import React from 'react'
import { Link as RouterLink } from 'react-router-dom'

import { useSelector } from 'react-redux'
import { routes } from 'routing/routes'
import { Flex, Grid } from 'components'

import './header.scss'
const DEFAULT_PROFILE_PHOTO = "https://magiei-resources.s3.us-east-2.amazonaws.com/Icons/icon-mt-user.svg"
const Header = () => {
    // const [photoUrl, setPhotoUrl] = useState(DEFAULT_PROFILE_PHOTO)

    // useEffect(() => {
    //     axios.get('/user/account', {
    //         headers: {
    //             'Accept': 'application/json',
    //             'Content-Type': 'application/json;charset=UTF-8',
    //             'Authorization': `Bearer ${localStorage.getItem('token')}`
    //         }
    //     }).then(res => {
    //         const {data} = res 
    //         setPhotoUrl(data.user.photoUrl || DEFAULT_PROFILE_PHOTO)
    //     }).catch(err => {
    //         // setError(err.response.statusText)
    //     })
    // }, []);
    const { auth } = useSelector(store => store)

    const imageClassName = (auth.user?.photoUrl === DEFAULT_PROFILE_PHOTO || auth.user?.photoUrl === "") ? "user_info__default" : "account__user_photo"

    return (
        <Flex className="header" align="center" justify="space-between" padding="1.14em">
            <RouterLink to={routes.home.path}>
                <Grid className="header__button">
                    <img alt="" className="header__home" src="https://magiei-resources.s3.us-east-2.amazonaws.com/Icons/icon-mt-home.svg" />
                </Grid>
            </RouterLink>
            <RouterLink to={routes.home.path}>
                <img alt="" src="https://inteligeneresources.s3.us-east-2.amazonaws.com/Imagenes/mediterms-logo.png" />
            </RouterLink>
            <RouterLink to={routes.profile.path}>
                <Grid className="header__button">
                    <img alt="" className={imageClassName} src={auth.user?.photoUrl || DEFAULT_PROFILE_PHOTO} />
                </Grid>
            </RouterLink>
        </Flex>
    )
}

export default Header
