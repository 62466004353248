import React from 'react'
import { matchPath, Outlet, useLocation } from 'react-router-dom'

import { Grid } from 'components'

import Header from './Header/Header'
import './styles.scss'
import { noHeaderRoutes } from 'routing/routes'

const Layout = () => {
    const location = useLocation()
    const showHeader = !noHeaderRoutes.find(route => matchPath({ path: route }, location.pathname))

    return (
        <Grid className="app_container" rows={showHeader ? 'auto 1fr' : ''}>
            {showHeader && <Header />}
            <Grid className="layout_content">
                <Outlet />
            </Grid>
        </Grid>
    )
}

export default Layout
